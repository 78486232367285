<template>
    <v-container fluid>
        <v-form @submit.prevent="getOrders">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline pa-0">{{ $t('my_orders') }}</v-subheader>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </ValidationObserver>
        </v-form>


        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="orderItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalOrders" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer
                              disable-pagination
                              single-expand :expanded.sync="expanded" item-key="order_uuid" show-expand
                >

                    <template v-slot:item.on_date="{ item }">
                        {{  item.on_date ? $moment(item.on_date).format('DD.MM.YYYY') : ''}}
                    </template>
                    <template v-slot:item.is_cash="{ item }">
                        {{ item.is_cash ? $t('yes') : $t('no')}}
                    </template>


                    <template v-slot:item.action="{ item }" class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showDetailsOrder(item)" :title="$t('details')">
                                    mdi-text-box-outline
                                </v-icon>
                            </template>
                            <span>{{$t('order_details')}}</span>
                        </v-tooltip>



                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="CancelOrder(item)" :title="$t('cancel')"
                                        :disabled="(item.status_code === 'Отменен' || item.status_code === 'КОтгрузке' ) ? true : false"
                                >
                                    mdi-text-box-remove-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('cancel') }}</span>
                        </v-tooltip>


                    </template>


                    <template v-slot:expanded-item="{ headers, item}">
                        <td :colspan="headers.length">
                            <v-row class="py-2 my-2">
                                <v-col cols="12" sm="6" v-for="(products, index) in item.products" :key="index">
                                    <v-hover
                                        v-slot="{ hover }"
                                        open-delay="200"
                                    >
                                        <v-card
                                            :elevation="hover ? 16 : 2"
                                            :class="{ 'on-hover': hover }"
                                        >
                                            <v-card-text>
                                                <p class="py-0 my-0"><b>{{ $t('product_name') }}:</b> {{ products.name }}</p>
                                                <p class="py-0 my-0"><b>{{ $t('barcode') }}:</b> {{ products.barcode }}</p>
                                                <p class="py-0 my-0"><b>{{ $t('price_per_piece') }}:</b> {{ products.price }}</p>
                                                <p class="py-0 my-0"><b>{{ $t('quantity_to_be_shipped') }}:</b> {{ products.count }}</p>
                                                <p class="py-0 my-0"><b>{{ $t('total') }}:</b> {{  products.price *  products.count }}</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>


                        </td>
                    </template>

                </v-data-table>
                <v-card-actions class="justify-center">
                    <v-pagination v-show="totalOrders > perPage" v-model="page" :total-visible="totalVisiblePag"
                                  :length="pageCount" :disabled="loading"></v-pagination>
                </v-card-actions>
            </v-card-text>

        </v-card>


        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" class="text-center">
                            <v-col cols="12" class="py-1"><b> {{ $t('track_number')}}: </b>{{ detailsItems.track_number ? detailsItems.track_number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('status')}}: </b>{{ detailsItems.status ? detailsItems.status :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('dispatch_date')}}: </b>{{ detailsItems.dispatch_date ? detailsItems.dispatch_date :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('client_name')}}: </b>{{ detailsItems.delivery.name ? detailsItems.delivery.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('client_phone')}}: </b>{{ detailsItems.delivery.phone ? detailsItems.delivery.phone :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('delivery_address')}}: </b>{{ detailsItems.delivery.address ? detailsItems.delivery.address :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('client_comment')}}: </b>{{ detailsItems.comment ? detailsItems.comment :  $t('no_data')  }}</v-col>
                        </v-col>

                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row class="p-2 m-2">
                            <v-col cols="12" sm="6" v-for="(products, index) in detailsItems.products" :key="index">
                                <v-hover
                                    v-slot="{ hover }"
                                    open-delay="200"
                                >
                                    <v-card
                                        :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }"
                                    >
                                        <v-card-text>
                                            <p class="py-0 my-0"><b>{{ $t('product_name') }}:</b> {{ products.name }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('barcode') }}:</b> {{ products.barcode }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('price_per_piece') }}:</b> {{ products.price }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('quantity_to_be_shipped') }}:</b> {{ products.count }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('total') }}:</b> {{  products.price *  products.count }}</p>
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: "Orders",
    components: {
        ValidationObserver,
        //ValidationProvider
    },
    data() {
        return {
            loading: false,
            dialogDetails: false,

            order_uuid: null,



            detailsItems:[],
            options: {},
            orderItems: [],
            expanded: [],
            sortBy: "state",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalOrders: 0,

            headers: [
                {
                    text: this.$t('dispatch_date'),
                    align: "center",
                    sortable: false,
                    value: "dispatch_date"
                },
                {
                    text: this.$t('state'),
                    align: "center",
                    sortable: false,
                    value: "status"
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 90,
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: 10,
                    class: "handle",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems','orderCache']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getOrders()
            },
            deep: false
        },


    },
    methods: {


        cancelDetails(){
            this.dialogDetails = false;
        },
        async showDetailsOrder(item) {
            this.loading = true;

            await this.$http
                .get("wms/order/"+item.order_uuid)
                .then(res => {
                    this.detailsItems = res.body.data
                    this.dialogDetails = true;
                    this.loading = false;
                })
                .catch(err => {
                    this.detailsItems = []
                    this.$toastr.error(this.$t('failed_to_get_order'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getOrders() {
            var _this = this
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.lang) {
                params.language = this.lang
            }



            await this.$http
                .get("wms/order", {
                    params: params,
                })
                .then(res => {
                    this.orderItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalOrders = res.body.meta.total
                    this.pageCount = res.body.meta.last_page

                })
                .catch(err => {
                    this.orderItems = []
                    this.page = 0
                    this.totalOrders = 0
                    this.pageCount = 0
                    this.$toastr.error(this.$t('failed_to_get_list_orders'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async CancelOrder(item) {
            if (confirm(this.$t('cancel_order'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`wms/order/${item.order_uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('order_has_been_cancel'))
                        this.getOrders()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('order_has_not_been_cancel'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },



    }
}
</script>
